import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Footer from './Footer';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        address: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, contact, address } = formData;
        const whatsappMessage = `Name: ${name}\nEmail: ${email}\nContact: ${contact}\nAddress: ${address}`;
        const whatsappUrl = `https://wa.me/6307208165?text=${encodeURIComponent(whatsappMessage)}`;
    
        window.open(whatsappUrl, '_blank');
      };
    
  return (
    <>

    <center><h1>Contact</h1></center>
     <div className='form'>
      <div className='form2'>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter email"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupContact">
            <Form.Label>Contact</Form.Label>
            <Form.Control
              type="text"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              placeholder="Enter contact number"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Enter contact address"
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
      <div className='form2'>
        <h2>D.P. PHYSIOTHERAPY CLINIC TIMING</h2>
        <p>Timing: 8:00AM to 2:00PM</p>
        <p>Evening: 4:00PM to 8:00PM</p>
        <p>Monday to Saturday (Sunday Closed)</p>
        <p>CALL US: 6307208165</p>
      </div>
    </div>

    <Footer/>
    
    </>
  )
}

export default Contact