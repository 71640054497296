import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../img/logo.png';


const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg ">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand" >
                        <b> <img src={logo} /> </b>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" className="nav-link" onClick={handleNavItemClick}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/About2" className="nav-link" onClick={handleNavItemClick}>About</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">Facility</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Swd.</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Ultrasonic Therapy</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Interferential Therapy</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Nerve Stimulator</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Muscle Stimulator</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Laser</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>C.P.M.</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Biofeedback</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Taping</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Dry Needling</Link></li>
                                </ul>
                            </li>


                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Specialization</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>ORTHO ISSUES</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>SPORT ISSUES</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>POSTURAL ISSUES</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>NEURO ISSUES</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>ORTHO, SPORT & FITNESS</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>PEDIATRIC ISSUES</Link></li>
                                </ul>
                            </li>


                            <li className="nav-item">
                                <Link to="/ImageGallery" className="nav-link" onClick={handleNavItemClick}>Gallery</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Contact" className="nav-link" onClick={handleNavItemClick}>Contact</Link>
                            </li>

                           

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
