import React from 'react';

// Importing images
import gal1 from '../img/gal1.jpg';
import gal2 from '../img/gal2.jpg';
import gal3 from '../img/gal3.jpg';
import gal4 from '../img/gal4.jpg';
import gal5 from '../img/gal5.jpg';
import gal6 from '../img/gal6.jpg';
import gal7 from '../img/gal7.jpg';
import gal8 from '../img/gal8.jpg';
import gal9 from '../img/gal9.jpg';
import gal10 from '../img/gal10.jpg';
import gal11 from '../img/gal11.jpg';
import Footer from './Footer';


// Array of images with alt text
const images = [
  { src: gal1, alt: 'Image 1' },
  { src: gal2, alt: 'Image 2' },
  { src: gal3, alt: 'Image 3' },
  { src: gal4, alt: 'Image 4' },
  { src: gal5, alt: 'Image 5' },
  { src: gal6, alt: 'Image 6' },
  { src: gal7, alt: 'Image 7' },
  { src: gal8, alt: 'Image 8' },
  { src: gal9, alt: 'Image 9' },
  { src: gal10, alt: 'Image 10' },
  { src: gal11, alt: 'Image 11' },

];

const ImageGallery = () => {
  return (
    <>
      <center><h1>Gallery</h1></center>
      <div className="gallery">

        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>



      <Footer />

    </>
  );
};

export default ImageGallery;
