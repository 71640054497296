import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Navbar from './components/Navbar.jsx'
import Home from './components/Home.jsx';
import ImageGallery from './components/ImageGallery.jsx';
import Contact from './components/Contact.jsx';
import About2 from './components/About2.jsx';

function App() {
  return (
    <div className='main'>

      <Router >

        <Navbar />
        <Routes >
          <Route path="*" element={<Home />} />
          <Route path="/About2" element={<About2 />} />
          <Route path="/ImageGallery" element={<ImageGallery />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
