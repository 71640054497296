import React from 'react';

const treatments = [
  {
    title: 'Orthopedic Issues',
    description: 'Treatment options for bone, joint, and muscle conditions including fractures, arthritis, and more.'
  },
  {
    title: 'Sports Issues',
    description: 'Specialized care for sports-related injuries such as sprains, strains, and dislocations.'
  },
  {
    title: 'Postural Issues',
    description: 'Therapies and interventions for improving posture and alleviating related pain.'
  },
  {
    title: 'Neurological Issues',
    description: 'Comprehensive treatment for conditions affecting the nervous system such as stroke, multiple sclerosis, and more.'
  },
  {
    title: 'Orthopedic, Sport & Fitness',
    description: 'Integrated care plans combining orthopedic and sports medicine to enhance overall fitness and recovery.'
  },
  {
    title: 'Pediatric Issues',
    description: 'Specialized treatments for children’s health issues, including congenital conditions and growth-related problems.'
  }
];

const Treatment = () => {
  return (
    <section className='treats'>
      <center>   <h2>Our Services </h2></center>
      <br/>
      <div className="treatment-list">
        {treatments.map((treatment, index) => (
          <div key={index} className="treatment-item">
            <h3>{treatment.title}</h3>
            <p>{treatment.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Treatment;
