import React from 'react'
import Carousal from './Carousal';
import About from './About';
import Footer from './Footer';
import Cont from './Cont';
import Treatment from './Treatment';

const Home = () => {
  return (
    <div className='main'>
      <Carousal/>
      <About/>
      <Cont/>
      <Treatment/>
      <Footer/>
    </div>
  )
}

export default Home