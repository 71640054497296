import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <div className='footer'>
                <div className='foot'>
                    <h1>Location</h1>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14245.456706144387!2d80.88671399999998!3d26.796530500000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfeaf7ecea2b3%3A0xa42b154cb26a432c!2sKrishna%20Nagar%2C%20Alambagh%2C%20Lucknow%2C%20Uttar%20Pradesh%20226023!5e0!3m2!1sen!2sin!4v1719827667125!5m2!1sen!2sin" ></iframe>

                </div>
                <div className='foot'>
                    <h1>Facility </h1>
                    <Link to='' className='best'> <p>Ultrasonic Therapy</p></Link>
                    <Link to='' className='best'> <p>Interferential Therapy</p></Link>
                    <Link to='' className='best'> <p>Nerve Stimulator</p></Link>
                    <Link to='' className='best'> <p>Muscle Stimulator</p></Link>
                    <Link to='' className='best'> <p>Laser</p></Link>


                </div>
                <div className='foot'>
                    <h1>Treatment</h1>
                    <Link to='/' className='best'> <p>ORTHO ISSUES</p></Link>
                    <Link to='/' className='best'> <p>SPORT ISSUES</p></Link>
                    <Link to='/' className='best'> <p>POSTURAL ISSUES</p></Link>
                    <Link to='/' className='best'> <p>NEURO ISSUES</p></Link>
                    <Link to='/' className='best'> <p>ORTHO, SPORT & FITNESS</p></Link>
                </div>
                <div className='foot'>
                    <h1>Reach Us </h1>
                   
                    <p>CALL US: 6307208165</p>
                    <p>
                    5/634, Sector-5, Gomti Nagar Extension, Near Tehsil (Manya Sweet) Shaheedpath, Lucknow</p>
                    <p>dpphysiotherapy@gmail.com</p>

                  
                </div>
            </div>

            <div className='disclamer'>
                <p>@ 2024 D.P. PHYSIOTHERAPY CLINIC  Designed by Creative Digital World </p>
            </div>


        </>
    )
}

export default Footer