import React from 'react'
import about from '../img/about.jpg';

const About = () => {
    return (
        <div className='about'>
            <div className='about2'>
                <img src={about} />
            </div>
            <div className='about2'>
                <h1>D.P. PHYSIOTHERAPY CLINIC</h1>
                <p>At D.P. Physiotherapy Clinic, our mission is to provide personalized, high-quality physiotherapy care to help our patients achieve optimal health and well-being. Our clinic is equipped with state-of-the-art facilities and a dedicated team of experienced physiotherapists committed to offering a comprehensive range of services to meet your rehabilitation and therapeutic needs.</p>
                <h2>DR. DEEPAK PANDEY</h2>
                <p>We believe in a patient-centered approach to healthcare, focusing on individual needs and treatment plans tailored to each patient. Our goal is to not only treat symptoms but also to address the root causes of pain and discomfort, promoting long-term health and mobility.</p>
                <p>BPT</p>
                <p>Certified Cupping Therapy</p>
                <p>Certified Dry Needling</p>
                <p>BSc. (Bio)</p>
                <p>Certified Strength Training</p>
                <p>Member of UP Medical</p>

            </div>
        </div>
    )
}

export default About